import React from 'react'
import { Link } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faDownload } from '@fortawesome/pro-regular-svg-icons'
import Showdown from 'showdown'

class solutionBulletsHorizontal extends React.Component {
 render() {
  const textClass = this.props.sectionClass.includes('bg-blue-gradient') ? 'text-white' : 'text-black' 
  const justify = this.props.sectionCenter ? 'justify-content-center' : 'justify-content-left'
  const textJustify = this.props.sectionCenter ? 'text-center' : 'text-left'
  const cardColour = this.props.sectionClass.includes('bg-blue-gradient') ? 'card-body-transparent' : this.props.sectionClass.includes('bg-white') ? 'card-body-grey' : ''
  const cardPadding = this.props.sectionClass.includes('bg-blue-gradient') ? 'p-0' : this.props.sectionClass.includes('bg-white') ? 'card-body-grey' : ''
  const converter = new Showdown.Converter()
  const Heading = this.props.data.htag ? this.props.data.htag : 'h2'
  const headingClass = this.props.data.htag === 'h3' ? 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium mb-2' : 'font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3'

  return (
    <section className={ this.props.sectionClass ? this.props.sectionClass : `bg-white` } key={this.props.key}>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12">

          {!this.props.data.title.includes('***') && 
            <Heading className={`${headingClass} ${textClass} ${textJustify}`}>
              {this.props.data.title}
            </Heading>
            }

              {this.props.data.description &&
                <div
                  className={`text-medium richtext ${textClass} ${textJustify}`}
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(this.props.data.description),
                  }}
                />
              }

          </MDBCol>
        </MDBRow>

        <MDBRow className={`font-w-400 text-medium letter-spacing-1 mt-4 mb-0 ${justify}`}>
          {this.props.data.feature.map((features, index) => (
            <MDBCol lg="4" xs="12" className="mb-4 d-flex" key={index}>
              <div className={`card flex-fill outline ${cardColour}`}>
                <div className={`card-body d-flex${this.props.sectionClass.includes('bg-blue-gradient') ? '' : ' card-hover' } ${cardPadding}`}>
                  {features.icon &&
                  <div style={{ fontSize: '2.5rem' }}>
                  <FontAwesomeIcon
                    icon={['fat', features.icon]}
                    className={`va-m mr-3 fa-fw ${this.props.sectionClass.includes('bg-blue-gradient') ? 'ico-color-white' : 'ico-color-blue' }`}
                  />
                  </div>
                  }
                  <div>
{/*                   {features.title &&
                    <div className={`font-alt font-w-700 letter-spacing-1 text-xs-large text-large pb-2 ${textClass}`}>
                      {features.title}
                    </div>
                  } */}
                  {features.title &&
                    <div className={`font-alt font-w-700 letter-spacing-1 text-large mb-0 ${textClass}`}
                    dangerouslySetInnerHTML={{
                      __html: converter.makeHtml(features.title),
                    }}                    
                    />
                  }
                  {features.description &&
                    <div
                      className={`font-w-400 text-medium divlink ${textClass}`}
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(features.description),
                      }}
                  />
                 }
                  </div>
                </div>
              </div>
            </MDBCol>
          ))}
        </MDBRow>

        {this.props.data.linktext &&
        <MDBRow className={`pt-3 ${textJustify}`}>
          <MDBCol>
            {this.props.data.link ? (
              <Link to={this.props.data.link} className="nav-link btn btn-hero-color btn-sm-block mt-3" >
                <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                {this.props.data.linktext}
              </Link>
            ) : (
              <>
              {this.props.data.document &&
                <a href={this.props.data.document.publicURL} className="nav-link btn btn-hero-color btn-sm-block mt-3" target="_blank" >
                  <FontAwesomeIcon icon={faDownload} className="mr-2" />
                  {this.props.data.linktext}
                </a>
              }
              </>
            )}
          </MDBCol>
        </MDBRow>
        }
      </MDBContainer>
    </section>
  )
 }
}

export default solutionBulletsHorizontal